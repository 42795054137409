"use client";
import { Box, Button, Center, Stack, Title } from "@mantine/core";
import Footer from "../components/Footer/footer";

import { useRouter } from "next/navigation";

export default function NotFound() {
  const router = useRouter();
  return (
    <>
      <Center>
        <Stack mt="150px" w="500px" align="center">
          <Title mt="md" size="2rem" p="25px">
            404 Not Found
          </Title>
          <Button
            variant="filled"
            color="var(--colors-bg-brand)"
            size="sm"
            radius="sm"
            onClick={() => {
              router.push("/");
            }}
            style={{
              fontFamily: "var(--mantine-font-family-headings)",
            }}
          >
            Return Home
          </Button>
        </Stack>
      </Center>
      <Box mt="45vh" position="absolute">
        <Footer />
      </Box>
    </>
  );
}
